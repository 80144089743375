import { Button, Checkbox, Modal } from "@nextui-org/react";
import { DownloadingButton } from "./DownloadingButton";

export default function SecondPartAppoinmentEndModal(props: any) {
  const {
    setselectedValue,
    setDisplayEmailPhoneClient,
    setSelectedStep,
    formikTask,
    documentationLanguage,
    documentacion,
    DowloadDocumentation,
    isLoadingDocumentation,
    showLanguagesOptions,
    setShowLanguagesOptions,
    setDocumentationLanguage,
    downloadBase64File,
    planosFavoritos,
  } = props;

  return (
    <>
      <Modal.Body css={{ padding: "2rem" }}>
        <h1
          style={{
            fontFamily: "lis-body",
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Recuerda
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <div>
            <Checkbox
              value={formikTask.values.contactoagendado}
              onChange={() => {
                formikTask.setFieldValue(
                  "contactoagendado",
                  !formikTask.values.contactoagendado
                );
              }}
            >
              Agendado próximo contacto
            </Checkbox>
          </div>
          <div>
            <Checkbox
              value={formikTask.values.emailenviado}
              onChange={() => {
                formikTask.setFieldValue(
                  "contactoagendado",
                  !formikTask.values.emailenviado
                );
              }}
            >
              Enviar el email con documentación en el siguiente paso
            </Checkbox>
          </div>
          <div>
            <Checkbox
              value={formikTask.values.informacionsolicitada}
              onChange={() => {
                formikTask.setFieldValue(
                  "contactoagendado",
                  !formikTask.values.informacionsolicitada
                );
              }}
            >
              Solicitar información necesaria para resolver dudas que no he
              podido aclarar durante la reunión.
            </Checkbox>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button
            auto
            css={{ background: "var(--blue-lis)" }}
            onClick={() => {
              setSelectedStep(0);
            }}
          >
            Volver
          </Button>

          <div className="d-flex gap-2">
            {planosFavoritos.length > 0 && (
              <DownloadingButton
                isLoadingDocumentation={isLoadingDocumentation}
                showLanguagesOptions={showLanguagesOptions}
                setDocumentationLanguage={setDocumentationLanguage}
                setShowLanguagesOptions={setShowLanguagesOptions}
                DowloadDocumentation={DowloadDocumentation}
                documentacion={documentacion}
                downloadBase64File={downloadBase64File}
              ></DownloadingButton>
            )}
            <Button
              css={{ background: "var(--blue-lis)" }}
              onClick={() => {
                setDisplayEmailPhoneClient(true);
                setSelectedStep(2);
                setselectedValue("fin_de_la_cita");
              }}
              disabled={isLoadingDocumentation}
            >
              Resumen de la cita (Paso 2 de 3)
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}
