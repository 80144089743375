import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import { pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AedasLoading from "./Components/AedasLoading/AedasLoading";
import Castillete from "./Components/Castillete/Castillete";
import Header from "./Components/Header/Header";
import useAuth from "./Hooks/useAuth";
import { useFetch } from "./Hooks/useFetch";
import Client from "./Pages/Client/Client";
import HomeExample from "./Pages/Home/HomeExample";
import Seller from "./Pages/Seller/Seller";
import Appointment from "./Pages/appointment/Appointment";
import Demo from "./Pages/demo/demo";
import { userLogin } from "./Redux/User/UserAction";
import { checkCoordinator } from "./Service/CoordinatorCheck/CoordinatorCheck";
import { getUser, goToURL } from "./Utils/commonUtils";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import BuildIcon from "@mui/icons-material/Build";
import { isAdminUser } from "./Utils/permissionsUtils";

function App() {
  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <Router></Router>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingAll, setIsLoading] = useState(true);
  const history = useHistory();
  const [isCoordinator, isCoordinatorIsFething, updateIsCoordinator] = useFetch(
    checkCoordinator,
    getUser()?.id
  );

  const location = useLocation();
  const { isLogin, token, user, isLoading } = useAuth();

  useEffect(() => {
    if (isLogin && !isLoading) {
      dispatch(userLogin(user, token));
    } else if (!isLogin && !isLoading) {
      window.location.href =
        process.env.REACT_APP_LOGIN +
        `?r=${process.env.REACT_APP_BASE_REDIRECT}`;
    }
  }, [isLogin, isLoading]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isLogin && localStorage.getItem("seller_AEDAS_demo")) {
      setIsLoading(false);
      history.push(location.pathname);
    } else if (isLogin) {
      if (process.env.REACT_APP_IS_PRO === "true" && !isAdminUser()) {
        history.push(location.pathname);
      } else if (localStorage.getItem("seller_AEDAS_demo")) {
        history.push("/");
      } else {
        history.push("/demo");
      }

      setIsLoading(false);
    }
  }, [history, isLogin, location.pathname]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  useEffect(() => {
    if (getUser()) {
      updateIsCoordinator();
    }
  }, [getUser()?.id]);

  const menu: any = useMemo(() => {
    let menuAux = [
      {
        name: "Home",
        localPath: "/",
        icon: <HomeIcon />,
        onClick: history.push,
        canView: true,
        shortName: "Home",
        hasPermissions: true,
      },
      {
        name: "Configurador",
        localPath: process.env.REACT_APP_CONFIGURADOR as string,
        icon: <AddCircleOutlineIcon />,
        onClick: goToURL,
        canView: isCoordinator,
        shortName: "Config",
        hasPermissions: isCoordinator,

        children: [
          {
            name: "Promociones",
            localPath:
              (process.env.REACT_APP_CONFIGURADOR as string) + "/promociones",
            icon: <BuildIcon />,
            onClick: goToURL,
            shortName: "Config",
            canView: isCoordinator,
          },
          {
            name: "Simulación Hipotecas",
            localPath:
              (process.env.REACT_APP_CONFIGURADOR as string) + "/hipotecas",
            icon: <HomeRepairServiceIcon />,
            onClick: goToURL,
            shortName: "Hipoteca",
            canView: isCoordinator,
          },
        ],
      },
    ];
    return menuAux;
  }, [isCoordinator]);

  if (isLoadingAll || isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            zIndex: 9999, // Asegura que el z-index sea mayor que el del modal
          },
        }}
      />
      <div className="row" style={{ height: "100vh" }}>
        {isLogin &&
        !location.pathname.includes("/client/") &&
        !location.pathname.includes("/demo") ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          style={
            isLogin && !location.pathname.includes("/client/")
              ? {
                  flex: 1,
                  marginLeft: isOpen ? "300px" : "70px",
                  width: "calc(100% - 70px)",
                }
              : {}
          }
        >
          <Switch>
            {/* <Route exact path="/login">
                  <Login isLogin={isLogin}></Login>
                </Route> */}
            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/">
              <HomeExample></HomeExample>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/appointment/:id"
            >
              <Appointment></Appointment>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/demo"
            >
              <Demo></Demo>
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/appointment/:id_appoiment/seller/:id/:edition?"
            >
              <Seller></Seller>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/castillete"
            >
              <Castillete></Castillete>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/appointment/:id_appoiment/client/:id"
            >
              <Client></Client>
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </>
  );
};

const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    window.location.href =
      process.env.REACT_APP_LOGIN +
      `?r=${process.env.REACT_APP_BASE_REDIRECT}/`;
    return <></>;
  }
};
