import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { getDocumentationNew } from "../../Service/DocumentsService/DocumentsService";
import { getHouseInfoByAssets } from "../../Service/HouseInfoByAssets/HouseInfoByAssets";
import { getLikesService } from "../../Service/LikesService/LikesService";
import FirstPartAppoinmentEndModal from "./FirstPartAppoinmentEndModal";
import SecondPartAppoinmentEndModal from "./SecondPartAppoinmentEndModal";
import ThirdPartAppoinmentEndModal from "./ThirdPartAppoinmentEndModal";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function SwitchModal(props: any) {
  const [loading, setLoading] = useState<any>(true);
  const [planosFavoritos, setPlanosFavoritos] = useState<any>([]);
  const [informacionViviendas, setInformacionViviendas] = useState<any>([]);
  const [documentacion, setDocumentacion] = useState<any>(null);
  const { id_appoiment }: any = useParams();
  const [isLoadingDocumentation, setIsLoadingDocumentation] = useState(false);
  const [showLanguagesOptions, setShowLanguagesOptions] = useState(false);
  const [documentationLanguage, setDocumentationLanguage] = useState("es");

  const {
    mainImage,
    setselectedValue,
    setDisplayEmailPhoneClient,
    setOpenModal,
    setSelectedStep,
    selectedStep,
    sendJson,
    setTab,
    promotion,
    client,
    formik,
    seconds,
    minutes,
    hours,
    setTipArchetype,
    setTipRelationalStyle,
  } = props;

  const formikTask = useFormik({
    initialValues: {
      contactoagendado: false,
      emailenviado: false,
      informacionsolicitada: false,
    },
    validationSchema: null,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const fetchData = async () => {
      if (client?.id && promotion?.id) {
        try {
          const likesResponse = await getLikesService(client.id, promotion.id);
          // funcion que a cada uno de los elementos del array planos que viene en la response le añada un nuevo campo "generate_file" con valor true
          likesResponse.data.planos = likesResponse.data.planos.map(
            (plano: any) => {
              return { ...plano, generate_file: true };
            }
          );
          setPlanosFavoritos(likesResponse.data.planos);
        } catch (error) {
          console.log(
            "Hubo un error al obtener las imágenes y los planos marcados como favoritos"
          );
        } finally {
          setLoading(false);
        }
      }
    };

    if (client?.id && promotion?.id) {
      fetchData();
    }
  }, []);

  function downloadBase64File(
    base64Data: string,
    fileName: string,
    mimeType: string
  ) {
    // Crear un enlace de descarga
    const link = document.createElement("a");

    // Configurar el contenido del enlace con el archivo en base64
    link.href = `data:${mimeType};base64,${base64Data}`;
    link.download = fileName;

    // Simular un clic en el enlace para iniciar la descarga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const DowloadDocumentation = (language: string) => {
    //limpiar objeto de los planos favoritos para mandar solo lo necesario
    const likes = planosFavoritos.map((plano: any) => {
      return {
        asset_id: plano.asset_id,
        generate_file: plano.generate_file,
        asset_name: plano.asset_name,
        url: plano.url,
        id_promo: plano.id_promo,
        id_house: plano.id_house,
      };
    });
    if (promotion?.id && !documentacion && likes.length > 0) {
      setIsLoadingDocumentation(true);
      getDocumentationNew(
        client.id,
        promotion?.id,
        id_appoiment,
        language,
        likes
      ).then((response: any) => {
        //se guardan todos los campos porque hacen falta para otros servicios (dia?)
        setDocumentacion(response.data);
        downloadBase64File(
          response.data.appended_documents,
          "documentación_viviendas.pdf",
          "application/pdf"
        );
        setIsLoadingDocumentation(false);
        setShowLanguagesOptions(false);
        // setDocumentacion(response.data);
      });
    } else {
      toast.error(
        "Debes marcar alguna vivienda como favorita para poder descargar la documentación"
      );
    }
  };

  // Obtenemos la información de las casas a través del ID de los planos.
  useEffect(() => {
    const assetIds = planosFavoritos?.map((plano: any) => plano.asset_id);
    if (assetIds && assetIds.length > 0) {
      getHouseInfoByAssets(assetIds).then((response: any) => {
        setInformacionViviendas(response.data);
      });
    }
  }, [planosFavoritos]);

  if (loading) return <div className='my-4'>Cargando...</div>;

  switch (selectedStep) {
    case 0:
      return (
        <FirstPartAppoinmentEndModal
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          formik={formik}
          promotion={promotion}
          client={client}
          sendJson={sendJson}
          documentacion={documentacion}
          DowloadDocumentation={DowloadDocumentation}
          isLoadingDocumentation={isLoadingDocumentation}
          showLanguagesOptions={showLanguagesOptions}
          setShowLanguagesOptions={setShowLanguagesOptions}
          setDocumentationLanguage={setDocumentationLanguage}
          downloadBase64File={downloadBase64File}
          planosFavoritos={planosFavoritos}
        ></FirstPartAppoinmentEndModal>
      );
    case 1:
      return (
        <SecondPartAppoinmentEndModal
          setselectedValue={setselectedValue}
          setDisplayEmailPhoneClient={setDisplayEmailPhoneClient}
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          formikTask={formikTask}
          promotion={promotion}
          client={client}
          sendJson={sendJson}
          documentacion={documentacion}
          DowloadDocumentation={DowloadDocumentation}
          isLoadingDocumentation={isLoadingDocumentation}
          showLanguagesOptions={showLanguagesOptions}
          setShowLanguagesOptions={setShowLanguagesOptions}
          setDocumentationLanguage={setDocumentationLanguage}
          downloadBase64File={downloadBase64File}
          planosFavoritos={planosFavoritos}
        ></SecondPartAppoinmentEndModal>
      );
    case 2:
      return (
        <ThirdPartAppoinmentEndModal
          mainImage={mainImage}
          promotion={promotion}
          client={client}
          setSelectedStep={setSelectedStep}
          setOpenModal={setOpenModal}
          setselectedValue={setselectedValue}
          sendJson={sendJson}
          setTab={setTab}
          formik={formik}
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          formikTask={formikTask}
          planosFavoritos={planosFavoritos}
          documentacion={documentacion}
          informacionViviendas={informacionViviendas}
          setTipArchetype={setTipArchetype}
          setTipRelationalStyle={setTipRelationalStyle}
          documentationLanguage={documentationLanguage}
          setDocumentationLanguage={setDocumentationLanguage}
          DowloadDocumentation={DowloadDocumentation}
          isLoadingDocumentation={isLoadingDocumentation}
          showLanguagesOptions={showLanguagesOptions}
          setShowLanguagesOptions={setShowLanguagesOptions}
          downloadBase64File={downloadBase64File}
          setDocumentacion={setDocumentacion}
        ></ThirdPartAppoinmentEndModal>
      );
    default:
      return <></>;
  }
}
