import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { userLogout } from "../../Redux/User/UserAction";
import { getUser } from "../../Utils/commonUtils";
import MainListItems from "../LisHeaderProvaider/listItems";
import "./Header.css";
import { ModalExit } from "../ModalExit/ModalExit";
import { useState } from "react";
import useAuth from "../../Hooks/useAuth";
import { isAdminUser } from "../../Utils/permissionsUtils";

function Header(props: any) {
  const { menu, setIsOpen, open } = props;
  const { user } = useAuth();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOpenClose = () => {
    setIsOpen(!open);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleLogOut = () => {
    if (process.env.REACT_APP_IS_PRO === "true" && !isAdminUser()) {
      localStorage.removeItem("aedas_user");
      localStorage.removeItem("in_canarias");
      dispatch(userLogout());
      window.location.href = process.env.REACT_APP_LOGIN + "/logout";
    } else {
      localStorage.removeItem("seller_AEDAS_demo");
      history.push("/demo");
    }
  };

  const handleAction = () => {
    history.push("/");
  };

  const handleModalOpen = () => {
    if (location.pathname !== "/") {
      setModalOpen(true);
    }
  };

  return (
    <header
      style={{
        width: open ? "300px" : "70px",
        height: "100%",
        backgroundColor: "white",
        position: "fixed",
        overflow: "hidden",
      }}
    >
      <ModalExit
        open={modalOpen}
        setOpen={setModalOpen}
        action={handleAction}
      />
      <div
        className='d-flex justify-content-center w-100'
        onClick={() => handleModalOpen()}
        style={{ cursor: "pointer" }}
      >
        <img
          alt='Logo AEDAS'
          src='/Assets\img\LogoAedasSmall.svg'
          style={{ height: "70px" }}
        ></img>
        {open && (
          <div className='d-flex align-items-center'>
            <h1 style={{ paddingLeft: "20px" }} className='appName'>
              Atenea
            </h1>
          </div>
        )}
      </div>
      <div className='openCloseButton_container'>
        <IconButton onClick={handleOpenClose} className='openCloseButton'>
          {open ? (
            <ArrowBackIosIcon></ArrowBackIosIcon>
          ) : (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          )}
        </IconButton>
      </div>

      <MainListItems
        setOpen={setIsOpen}
        open={open}
        menu={menu}
      ></MainListItems>
      {open ? (
        <div className='personHeader'>
          <hr style={{ margin: "20px" }}></hr>

          <div className=' p-2 col-2 w-100 d-flex justify-content-between '>
            <div
              style={{ cursor: "pointer" }}
              className='d-flex justify-content-center'
              onClick={() => setModalOpen(true)}
            >
              <img
                alt='Icono Perfil'
                className='imgPersonHeader'
                src={"/Assets/img/blank.svg"}
              ></img>
            </div>
            <div className='row d-flex align-items-center'>
              <div>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "var(--lis-title)",
                  }}
                >
                  {getUser().displayName}
                </h1>
                <h2
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    fontFamily: "var(--lis-title)",
                    color: "grey",
                  }}
                >
                  {/* De antes se mostraba la posición, pero en esta BBDD no se tiene, entonces se oculta */}
                </h2>
              </div>
            </div>
            <div className='row '>
              <div className='d-flex aling-items-center'>
                <IconButton
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <PowerSettingsNewIcon></PowerSettingsNewIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='personHeader '>
          <div className=' p-2 col-12 w-100 row'>
            <div className='d-flex justify-content-center'>
              <img
                alt='Icono Persona'
                className='imgPersonHeaderSmall'
                src={"/Assets/img/blank.svg"}
              ></img>
            </div>

            <div className='row'>
              <div className='d-flex justify-content-center'>
                <IconButton
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <PowerSettingsNewIcon></PowerSettingsNewIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
